div.noDataPage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div.noDataIconNInfo {
        height: 152px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 92px;
            height: 96px;
        }

        div.pArea {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                line-height: 1;
                font-family: 'NanumSquare';
                color: #0A2B83;
                &:nth-child(1) {
                    margin-top: 13px;
                    font-size: 20px;
                }

                &:nth-child(2) {
                    margin-top: 12px;
                    font-size: 15px;
                    font-weight: 300;
                }
            }
        }
    }
}