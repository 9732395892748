.modalContentArea.newMembers {
    position: relative;
    width: 882px;
    height: 550px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 58px;
    padding-left: 40px;
    padding-right: 40px;

    .modalTitle {
        line-height: 1;
        font-family: 'LeferiPointSpecial';
        font-size: 41px;
    }

    .modalBtn {
        outline: none;
        border: none;
    }

    h2 {
        margin-bottom: 85px;
    }

    .closeBtn {
        position: absolute;
        top: 21px;
        right: 25px;
        background-color: #0A2B83;
        color: white;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 14px;
            height: 14px;
            object-fit: contain;
        }
    }

    div.btnsArea {
        position: absolute;
        top: 117px;
        right: 115px;
        width: 363px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div.selectBtns {
            width: 196px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                width: 93px;
                height: 28px;
                border-radius: 14px;
                outline: none;
                font-family: 'LeferiPointWhite';
                font-size: 14px;
                line-height: calc(28px - 2pt);
                border: 1pt solid #45BFCE;

                &.chkAllBtn {
                    background-color: #45BFCE;
                    color: #FFF;
                }

                &.chkOffAllBtn {
                    background-color: #FFF;
                    color: #45BFCE;
                }
            }
        }

        div.approvalBtns {
            width: 128px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                width: 57px;
                height: 25px;
                border: none;
                outline: none;
                background-color: #0A2B83;
                border-radius: 2px;
                font-family: 'NotoSansKR';
                font-size: 16px;
                font-weight: 300;
                line-height: 25px;
                color: #FFF;
            }
        }
    }

    .tableWrapper {
        padding-left: 68px;
        padding-right: 75px;
        height: 323px;
        overflow-y: scroll;

        table.membersTable {
            width: 657px;
            border-spacing: 0;

            thead {
                // background-color: #FFF;
                background-image: linear-gradient(60deg, #45BFCE 0%, #0A2B83 100%);
                // position: sticky;
                // top: 0px;
                // z-index: 3;

                tr th {
                    // background-color: #E9EDF5;
                    // border: 5pt solid #FFF;
                    height: 28px;
                    font-family: 'LeferiPointWhite';
                    font-size: 16px;
                    color: #FFF;

                    &.userChkbox .innerDiv {
                        width: 47px;
                    }

                    &.userName .innerDiv {
                        width: 125px;
                    }

                    &.userEmail .innerDiv {
                        width: 134px;
                    }

                    &.userCompany .innerDiv {
                        width: 134px;
                    }

                    &.userRank .innerDiv {
                        width: 117px;
                    }

                    &.approval .innerDiv {
                        width: 98px;
                    }
                }
            }

            tbody tr {
                &:last-child td {
                    border-bottom: 0.25pt solid #808080;
                }

                td {
                    height: 28px;
                    text-align: center;
                    font-family: 'LeferiPointWhite';
                    font-size: 16px;
                    color: #4D4D4D;

                    * {
                        font-family: 'LeferiPointWhite';
                        font-size: 16px;
                        color: #4D4D4D;
                    }

                    border-top: 0.25pt solid #808080;
                    border-left: 0.25pt solid #808080;

                    &.userRank>div,
                    &.approval>div {
                        // width: 170px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        div.selectBox {
                            width: 88px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            * {
                                font-size: 12px;
                                line-height: 1;
                            }

                            div.control {
                                width: 88px;
                                min-height: auto;
                                height: 18px;
                                border: 0.25pt solid #0A2B83;
                                border-radius: 2px;
                                // box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.45);
                                
                                div.singleVal {
                                    color: #0A2B83;
                                    text-align: start;
                                }    

                                div.valueContainer {
                                    padding: 0 15px;
                                }

                                span.arrowIcon {
                                    width: 17px;
                                    background-color: transparent;
                                    padding-right: 5px;

                                    img {
                                        width: 12px;
                                        height: 6px;
                                        object-fit: contain;
                                    }
                                }
                            }

                            .options {
                                margin-top: 8px;
                                max-height: 225px;
                                box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.65);

                                .option {
                                    height: 20px;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                }

                                .selected {
                                    background-color: #E9EDF5;
                                }
                            }

                        }

                    }

                    &.approval>div {

                        // width: 150px;
                        div.selectBox {
                            width: 88px;
                        }
                    }

                    &:last-child {
                        border-right: 0.25pt solid #808080;
                    }

                }
            }
        }
    }
}