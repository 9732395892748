.showMonitDataStructures {
    h2.pageTitle {
        margin-bottom: 32px;
    }

    div.sumDsArea {

        div.dataStructureTblArea {
            width: fit-content;

            ul.dataStructureTbl {
                width: fit-content;

                font-family: 'LeferiPointWhite';
                font-size: 16px;
                line-height: 1;

                li.row {
                    ul {
                        display: flex;

                        li.col {
                            width: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &.name {
                                width: 110px;
                            }

                            &.rawNo {
                                width: 130px;
                            }

                            &.rawName {
                                width: 140px;
                            }

                            &.rawType {
                                width: 100px;
                            }

                            &.rawDim {
                                width: 60px;
                            }

                            &.mqttTopic {
                                width: 110px;
                            }

                            &.comment {
                                width: 100px;
                            }

                            &.edit,
                            &.delete {
                                width: 50px;
                            }

                            >ul {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                height: 100%;

                                li.upper {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }

                            ul.innerCol {
                                display: flex;
                                width: 100%;
                                height: 100%;

                                >li {
                                    width: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }

                        }
                    }

                    &.th {
                        background-image: linear-gradient(120deg, #45BFCE 0%, #0A2B83 100%);
                        height: 64px;
                        border-top-left-radius: 7px;
                        border-top-right-radius: 7px;

                        li.col {
                            height: 64px;
                            color: #fff;
                            border: none;

                            li.upper {
                                border-bottom: 0.25px solid #FFF;
                                height: 50%;
                            }

                            li.under {
                                height: 50%;
                            }

                            ul.innerCol {

                                >li {
                                    &:last-child {
                                        border-left: 0.25px solid #FFF;
                                    }
                                }
                            }

                            &:not(:last-child) {
                                border-right: 0.25px solid #FFF;
                            }
                        }
                    }

                    &:not(.th) {
                        ul {
                            font-size: 14px;

                            li.col {
                                border-bottom: 1px solid #808080;
                                border-right: 1px solid #808080;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                // min-height: 40px;
                                min-height: 56px;
                                font-family: 'LeferiPointWhite';
                                color: #4D4D4D;

                                &:first-child {
                                    border-left: 1px solid #808080;
                                }

                                ul {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;

                                    &.innerCol {
                                        flex-direction: row;

                                        >li {
                                            height: 100%;

                                            &:last-child {
                                                border-left: 1px solid #808080;
                                            }
                                        }
                                    }

                                    li.innerCol {
                                        // text-align: center;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        height: 100%;

                                        &:not(:last-child) {
                                            border-bottom: 1px solid #808080;
                                        }
                                    }
                                }

                                button {
                                    width: 28px;
                                    height: 28px;
                                    border-radius: 14px;
                                    border: none;
                                    outline: none;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: #45BFCE;

                                    &.refValBtn {
                                        width: 43px;
                                        height: 26px;
                                        border-radius: 2px;
                                        color: #FFF;
                                        background-color: #0A2B83;
                                        line-height: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            div.btnsArea {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                margin-top: 19px;

                button.createBtn {
                    width: 66px;
                    height: 26px;
                    border-radius: 13px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 12px;
                    padding-right: 12px;
                    // padding: 0 8px;
                    line-height: 26px;
                    font-family: 'LeferiPointWhite';
                    font-size: 14px;
                    background-color: #45BFCE;
                    color: #FFF;
                    border: none;
                    outline: none;

                    img {
                        width: 11px;
                        height: 12px;
                    }
                }
            }
        }
    }
}

.editMonitDataStructure,
.createMonitDataStructure {
    h2.pageTitle {
        margin-bottom: 22px;
    }

    ul.formTbl {
        width: 997px;
        font-family: 'LeferiPointWhite';
        font-size: 16px;

        input {
            font-family: 'LeferiPointWhite';
            font-size: 14px;
        }

        >li.col {
            width: 100%;

            ul {
                display: flex;

                >li {
                    border-bottom: 1px solid #808080;
                    border-right: 1px solid #808080;
                    height: 37px;

                    input {
                        width: 100%;
                        height: 100%;
                        border: none;
                        outline: none;
                        padding: 0 8px;
                    }

                    &.printedInputLi {
                        height: 27px;
                    }

                    &.setName,
                    &.noRecord,
                    &.name {
                        height: 30px;
                    }

                    &.name {
                        border-bottom: none;
                    }

                    &.formTh {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-image: linear-gradient(60deg, #45BFCE 0%, #3CA8C3 100%);
                        width: 154px;
                        border: none;
                        border-bottom: 1px solid #FFF;
                        color: #FFF;
                    }

                    &:not(.formTh) {
                        width: calc(997px - 154px);

                        &.selectLi {
                            padding: 5px 15px;

                            .selectBox {
                                width: 136px;
                                height: 28px;
                                // border: 0.5pt solid #0A2B83;
                                border-radius: 5px;
                                outline: none;
                                box-shadow: 0px 0px 2px rgba($color: #000000, $alpha: 0.45);

                                .control {
                                    min-height: auto;
                                    height: 100%;
                                    border: 0.5pt solid #0A2B83;
                                    border-radius: 5px;
                                    // border: none;
                                    outline: none;
                                    // border-radius: 0;
                                    font-size: 13px;

                                    div.singleVal {
                                        color: #0A2B83;
                                    }

                                    div.valueContainer {
                                        padding: 0 26px;
                                    }

                                    .arrowIcon {
                                        background-color: transparent;
                                        // height: 20px;
                                        padding-right: 7px;

                                        img {
                                            width: 11px;
                                            height: 6px;
                                        }
                                    }
                                }

                                .options {
                                    margin-top: 10px;

                                    .option {
                                        padding: 0 26px;
                                        font-size: 13px;
                                        height: 24px;
                                        line-height: 24px;
                                    }
                                }
                            }
                        }
                    }

                    ul {
                        // width: 100%;
                        // height: 100%;
                    }
                }
            }

            &:first-child ul>li:not(.formTh) {
                border-top: 1px solid #808080;
            }

            &.dataRow {
                ul.dataRowTbl {
                    width: 100%;
                    flex-direction: column;

                    >li.col {
                        width: 100%;
                        height: fit-content;
                        border: none;

                        ul.dataRowTh {
                            height: 74px;
                            flex-direction: column;
                            background-image: linear-gradient(60deg, #45BFCE 0%, #0A2B83 100%);
                            color: #FFF;

                            li.col {
                                border: none;
                                width: 100%;

                                &.semiTtl {
                                    width: 100%;
                                    height: 37px;
                                    display: flex;
                                    // justify-content: center;
                                    align-items: center;


                                    ul {
                                        width: 100%;

                                        li {
                                            border-bottom: 1px solid #FFF;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            &:not(:last-child) {
                                                border-right: 1px solid #FFF;
                                            }

                                            &.sensorData {
                                                width: calc(100% / 3 * 2);
                                            }

                                            &.refVal {
                                                width: calc(100% / 3);
                                            }
                                        }
                                    }
                                }

                                li.th {
                                    background: transparent;
                                    // width: calc(100% / 6);
                                    height: 37px;
                                    border: none;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    &:not(:last-child) {
                                        border-right: 1px solid #FFF;
                                    }

                                }

                                li {
                                    width: calc(100% / 6);

                                }
                            }
                        }

                        .printedInputLi {
                            width: calc(100%/6);

                            &:first-child {
                                border-left: 1px solid #808080;
                            }

                            input {
                                text-align: center;
                            }
                        }

                        &.add {
                            height: 38px;
                            // border-bottom: 1px solid #808080;
                            // border-right: 1px solid #808080;

                            button {
                                width: 100%;
                                height: 100%;
                                border: none;
                                outline: none;
                                background-color: #0A2B83;
                            }
                        }
                    }
                }


                div {
                    position: relative;

                    button.delSD {
                        position: absolute;
                        left: calc(100% + 7px);
                        top: 50%;
                        transform: translateY(-50%);
                        width: 18px;
                        height: 18px;
                        border-radius: 1px;
                        border: none;
                        outline: none;
                        background-color: #0A2B83;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        >div {
                            width: 11px;
                            height: 1px;
                            background-color: #FFF;
                        }
                    }
                }

                ul {
                    li {
                        // width: 200px;
                        // height: 20px;

                        input {
                            width: 100%;
                            height: 100%;
                            outline: none;
                            border: none;
                        }
                    }
                }
            }
        }
    }

    div.btnsArea {
        width: 997px;
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;

        .formBtn {
            font-family: 'LeferiPointWhite';
            font-size: 14px;
            line-height: 26px;
            width: 60px;
            height: 26px;
            border-radius: 13px;
            border: none;
            outline: none;
            color: #FFF;
            background-color: #45BFCE;

            &:disabled {
                background-color: #808080;
                color: #CCC;
            }

            &.submitBtn {
                margin-right: 10px;
            }
        }
    }
}