.container {
    width: 100%;
}
.mainPage {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    width: 100%;
    padding-left: 68px;
    // position: ;

    nav.sideBar {
        width: 68px;
        height: 100vh;
        background-color: #0A2B83;
        padding-top: 204px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;


        ul {
            height: 228px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            li {
                a {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 68px;
                    height: 68px;
                    background-color: transparent;
                    img {
                        object-fit: contain;
                    }
                    &:hover span.hint {
                        display: inline-block;
                    }
                    span.hint {
                        position: absolute;
                        top: 40px;
                        left: 60px;
                        display: none;
                        width: max-content;
                        height: 30px;
                        background-color: #FFF;
                        border: 0.75pt solid #0A2B83;
                        border-radius: 4px;
                        padding: 0 12px;
                        font-family: 'LeferiPointWhite';
                        font-size: 16px;
                        line-height: calc(30px - 1.5pt);
                        color: #0A2B83;
                        z-index: 90;
                    }
                }
                

                // &:hover::after {
                //     display: block;
                // }

                &:nth-child(1)>a>img {
                    width: 23px;
                    height: 24px;
                }

                &:nth-child(2)>a>img {
                    width: 24px;
                    height: 24px;
                }

                &:nth-child(3)>a>img {
                    width: 29px;
                    height: 27px;
                }
            }
        }
    }

    div.contentArea {
        // width: calc(100vw - 68px);
        width: 100%;
        padding-top: var(--header-height);

        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        header {
            background-color: #E7E8EB;
            height: var(--header-height);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 20px;
            padding-right: 25px;
            position: fixed;
            top: 0;
            left: 68px;
            width: calc(100vw - 68px);
            z-index: 99;

            div.homeBtnArea {
                // width: 450px;
                display: flex;
                // justify-content: space-between;
                align-items: center;

                img {
                    width: 45px;
                    height: 45px;
                    object-fit: contain;
                    margin-right: 15px;
                }

                p {
                    font-family: 'NotoSansKr';
                    font-size: 28px;
                    color: #0A2B83;
                }
            }

            div.myAccountArea {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-family: 'LeferiPointWhite';
                font-size: 16px;
                div.myAccount {
                    margin-right: 35px;
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 10px;
                        width: 27px;
                        height: 27px;
                        object-fit: cover;
                    }

                    p {
                        color: #333;
                        line-height: 27px;
                    }
                }

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 87px;
                    height: 32px;
                    line-height: 42px;
                    background-color: #45BFCE;
                    outline: none;
                    border: none;
                    color: #FFF;
                    border-radius: 1.5px;
                    font-size: 14px;
                }
            }
        }

        div.content {
            width: 100%;
            min-height: var(--content-min-height);
            // overflow: auto;
            background-color: white;
            // padding: 46px 75px 76px 68px;

            >div {
                width: 100%;
                min-height: 100%;
                padding: 60px 75px 76px 68px;
                position: relative;

                .path {
                    position: absolute;
                    top: 15px;
                    left: 68px;
                    font-family: 'LeferiPointWhite';
                    font-size: 16px;
                    color: #0A2B83;
                    line-height: 1;
                    .below {
                        padding: 0 14px;
                    }
                }

                .pageTitle {
                    line-height: 1;
                    font-family: 'LeferiPointSpecial';
                    font-size: 41px;
                }
            }
        }
    }
}