.authMailInfo,
.notVerifiedYet {
    h2 {
        margin-bottom: 95px;
    }

    div.msgArea {
        height: 112px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-family: 'LeferiPointWhite';
        color: #4D4D4D;
        font-size: 16px;
        margin-bottom: 118px;

        p {
            line-height: 1;
            text-align: center;

            br {
                content: '';
                display: block;
                margin: 17px;
            }

            span.bold {
                font-family: 'LeferiPointSpecial';
                color: #0A2B83;
            }
        }

        div.userEmail {
            width: 274px;
            height: 35px;
            background-color: #F2F2F2;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .toLogIn {
        display: block;
        text-align: center;
        line-height: 46px;
        width: 354px;
        height: 46px;
        border-radius: 9px;
        background-color: #0A2B83;
        color: #FFF;
        font-family: 'LeferiBaseRegular';
        font-size: 16px;
        margin-bottom: 176px;
    }

    div.resendEmailArea {
        height: 67px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        button.resendEmail {
            border: none;
            outline: none;
            width: 288px;
            height: 35px;
            background-color: #6789D3;
            color: #EFEFEF;
            font-family: 'LeferiBaseRegular';
            font-size: 16px;
            line-height: 35px;
        }

        p.resendEmailMsg {
            font-family: 'LeferiPointWhite';
            font-size: 14px;
            color: #4D4D4D;
        }
    }

    &.notVerifiedYet {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div.msgArea {
            height: 189px;
            div.msg {
                height: 95px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                img.notVerifiedYetIcon {
                    width: 63px;
                    height: 44px;
                }
            }
        }

        div.resendEmailArea {
            height: 81px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 144px;

            button.resendEmail {
                border: none;
                outline: none;
                text-align: center;
                line-height: 46px;
                width: 354px;
                height: 46px;
                border-radius: 9px;
                background-color: #0A2B83;
                color: #FFF;
                font-family: 'LeferiBaseRegular';
                font-size: 16px;
            }

            p.resendEmailMsg {
                font-family: 'LeferiPointWhite';
                font-size: 14px;
                color: #4D4D4D;
            }
        }

        .toLogIn {
            display: block;
            text-align: center;
            margin-bottom: 0;
            border-radius: 0;

            width: 288px;
            height: 35px;
            background-color: #6789D3;
            color: #EFEFEF;
            font-family: 'LeferiBaseRegular';
            font-size: 16px;
            line-height: 35px;
        }


    }
}