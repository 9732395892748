div.notiWindow {
    width: 358px;
    height: 187px;
    background-color: #FFF;
    animation-duration: 3s;
    animation-name: fadeOut;
    animation-iteration-count: 1;
    padding-top: 48px;
    padding-bottom: 30px;
    div.innerDiv {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
        div.msgArea {
            height: 51px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
        }
        button {
            width: 160px;
            height: 27px;
            outline: none;
            border: none;
            border-radius: 5px;
            background-color: #0A2B83;
            color: #FFF;
            line-height: 1;
            font-family: 'LeferiPointWhite';
            font-size: 14px;
        }
    }
}