div.showSumDataStructs {
    .pageTitle {
        margin-bottom: 41px;
    }

    div.tblArea {
        width: 1542px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            height: 22px;
        }

        &::-webkit-scrollbar-track {
            background: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #0A2B83;
            border: 7px solid #ffffff;
            border-radius: 15px;
        }

        ul.sumDsTbl {
            font-family: 'LeferiPointWhite';
            font-size: 16px;
            width: fit-content;

            ul.tr {
                display: flex;

                >li {

                    div.divInTd {
                        min-height: 27px;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        p {
                            text-align: center;
                            width: 100%;
                            overflow: hidden; // 너비를 넘어가면 안보이게
                            text-overflow: ellipsis; // 글자가 넘어가면 말줄임(...) 표시
                            font-family: 'LeferiPointWhite';
                            font-size: 12px;
                            color: #4D4D4D;
                        }
                    }

                    &.name {
                        width: 150px;
                    }

                    &.dataNo {
                        width: 100px;
                    }

                    &.dataName {
                        width: 150px;
                    }

                    &.dataClass {
                        width: 100px;
                    }

                    &.indexNo {
                        width: 80px;
                    }

                    &.indexName {
                        width: 100px;
                    }

                    &.indexType {
                        width: 100px;
                    }

                    &.indexDim {
                        width: 50px;
                    }

                    &.indexStd {
                        width: 80px;
                    }

                    &.indexTol1 {
                        width: 80px;
                    }

                    &.indexTol0 {
                        width: 80px;
                    }

                    &.indexComment {
                        width: 120px;
                    }

                    &.organization {
                        width: 80px;
                    }

                    &.userOwner {
                        width: 100px;
                    }

                    &.mqttTopic {
                        width: 100px;
                    }

                    &.jsonKeys {
                        width: 100px;
                    }

                    &.comment {
                        width: 100px;
                    }

                    &.edit {
                        width: 50px;
                    }

                    &.delete {
                        width: 50px;
                    }
                }
            }

            li.td {
                height: 30px;

                // &:before {
                //     display: inline-block;
                //     vertical-align: middle;
                //     content: '';
                //     height: 100%;
                // }

                &.name {
                    border-left: 1px solid #333;
                }

                border-bottom: 1px solid #333;
                border-right: 1px solid #333;
            }

            li.thRow {
                background-image: linear-gradient(120deg, #45BFCE 0%, #0A2B83 100%);
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                color: white;
                
                ul.tr {
                    >li {
                        height: 64px;
                        line-height: 64px;
                        text-align: center;
                        font-family: "LeferiPointWhite";

                        &:not(:last-child) {
                            border-right: 1px solid #FFF;
                        }
                    }
                }
            }

            li.tdRow {
                li {
                    button {
                        width: 28px;
                        height: 28px;
                        border-radius: 14px;
                        border: none;
                        outline: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #45BFCE;

                        &.refValBtn {
                            width: 43px;
                            height: 26px;
                            border-radius: 2px;
                            color: #FFF;
                            background-color: #0A2B83;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }

    div.btnsArea {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 19px;

        button.createBtn {
            width: 66px;
            height: 26px;
            border-radius: 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 12px;
            padding-right: 12px;
            // padding: 0 8px;
            line-height: 26px;
            font-family: 'LeferiPointWhite';
            font-size: 14px;
            background-color: #45BFCE;
            color: #FFF;
            border: none;
            outline: none;

            img {
                width: 11px;
                height: 12px;
            }
        }
    }
}