.loginPageModal {
    width: 338px;
    height: 201px;
    padding: 31px 38px 31px 38px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    div.msg {
        height: 93px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            font-family: 'LeferiPointWhite';
            font-size: 16px;
            color: #272727;
            line-height: 31px;
            text-align: center;
        }
    }

    button.goBackBtn {
        width: 181px;
        height: 27px;
        border-radius: 5px;
        border: none;
        outline: none;
        background-color: #0A2B83;
        color: #FFF;
        font-family: 'LeferiPointWhite';
        font-size: 14px;
        line-height: 1;
    }

    &.registeredEmail {
        padding: 40px 26px 31px 26px;
        .msg {
            height: 83px;
            p {
                line-height: 1;
                br {
                    content: "";
                    display: block;
                    margin: 17px;
                }
            }
        }
    }
}