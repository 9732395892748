footer.footer {
    width: 100%;
    height: 115px;
    background-color: #E6E6E6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 253px;
    gap: 20px;
    img.kanLogo {
        width: 127px;
        height: 63px;
    }
    p.copyright {
        font-family: 'NotoSansKR';
        font-weight: 300;
        font-size: 14px;
        color: #0A2B83;
    }
}