@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

.dataPage {
    display: flex;
    padding: 0 !important;
    position: relative;

    div.cscBtns {
        width: 290px;
    }

    button.createBtn {
        width: 66px;
        height: 26px;
        border-radius: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
        // padding: 0 8px;
        line-height: 26px;
        font-family: 'LeferiPointWhite';
        font-size: 14px;
        background-color: #45BFCE;
        color: #FFF;
        border: none;
        outline: none;
        margin-right: 24px;

        img {
            width: 11px;
            height: 12px;
        }

        &.on {
            background-color: #FFF;
            color: #45BFCE;
            border: 1pt solid #45BFCE;
        }
    }

    button.editBtn {
        width: 60px;
        height: 26px;
        border: none;
        outline: none;
        border-radius: 13px;
        line-height: 26px;
        font-family: 'LeferiPointWhite';
        font-size: 14px;
        background-color: #45BFCE;
        color: #FFF;
        margin-right: 8px;

        &:disabled {
            background-color: #808080;
            color: #CCC;
            cursor: default;
        }
    }

    button.cancelBtn {
        width: 60px;
        height: 26px;
        border: none;
        outline: none;
        border-radius: 13px;
        line-height: 26px;
        font-family: 'LeferiPointWhite';
        font-size: 14px;
        background-color: #45BFCE;
        color: #FFF;

        &:disabled {
            background-color: #808080;
            color: #CCC;
            cursor: default;
        }
    }

    div.hintBtn {
        width: 22px;
        height: 22px;
        border-radius: 11px;
        background-color: #0A2B83;

        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        cursor: pointer;

        font-family: 'LeferiBaseRegular';
        font-size: 16px;

        &:hover {
            div.hintMsg {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        div.hintMsg {
            position: absolute;
            top: 50%;
            left: calc(100% + 7px);
            transform: translateY(-50%);
            width: 265px;
            height: 33px;
            background-color: #FFF;
            border: 1pt solid #0A2B83;
            border-radius: 6px;
            display: none;

            p {
                color: #0A2B83;
                font-family: 'LeferiPointWhite';
                font-size: 14px;
            }
        }

    }

    div.dataMenuArea {
        width: 138px;
        position: fixed;
        height: calc(100vh - 75px);
        background-color: #F2F2F2;
        padding-top: 228px;
        z-index: 1;

        .dataMenuLi {
            margin-bottom: 16px;

            .dataMenuBtn {
                background-color: transparent;
                text-align: start;
                font-size: 15px;
                height: 25px;
                font-family: 'NanumSquare';
                color: #4D4D4D;
                padding: 0px;
                display: flex;
                // justify-content: ;
                align-items: center;
                position: relative;

                .dataMenuIcon {
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    transform: translateY(-50%);
                }

                span {
                    margin-left: 36px;
                }

                &.on {
                    color: #0A2B83;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }

    }

    div.dataContentArea {
        padding: 60px 56px 76px 184px;
        width: 100%;
        // height: 100%;
        min-height: var(--content-min-height);

        // position: relative;
        >div {
            position: relative;

            .path {
                top: -45px !important;
                left: 0px !important;
            }
        }
    }
}