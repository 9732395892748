div.myPage {

    div.myInfo,
    div.editMyInfo {
        .pageTitle {
            margin-bottom: 124px;
        }

        .pageContent {
            position: relative;
            width: 898px;
            height: 590px;
            margin: 0 auto;

            .editMyInfoBtn {
                position: absolute;
                top: -118px;
                right: 0;
                width: 108px;
                height: 34px;
                border-radius: 17px;
                border: 1pt solid #45BFCE;
                outline: none;
                background-color: #45BFCE;
                font-family: 'LeferiPointWhite';
                font-size: 14px;
                color: #FFF;
                text-align: center;
                line-height: calc(34px - 2pt);
            }

            div.myInfoTblArea {
                width: inherit;
                height: inherit;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;

                table.myInfoTbl {
                    border-spacing: 0;

                    tbody {
                        tr {
                            td {
                                height: 70px;
                                border-left: 0.25pt solid #808080;
                                border-top: 0.25pt solid #808080;
                                border-bottom: 0.25pt solid #808080;
                                font-family: 'LeferiPointWhite';
                                font-size: 16px;
                                line-height: 100%;
                                color: #4D4D4D;

                                &.labelTd {
                                    width: 165px;
                                    text-align: center;
                                    background-image: linear-gradient(60deg, #45BFCE 0%, #0A2B83 100%);
                                    color: #FFF;
                                    border-top-left-radius: 6px;
                                    border-bottom-left-radius: 6px;
                                }

                                &.infoTd {
                                    width: 733px;
                                    border-right: 0.25pt solid #808080;
                                    padding: 0 33px;
                                    border-top-right-radius: 6px;
                                    border-bottom-right-radius: 6px;
                                }

                                >div {
                                    div.selectBox {
                                        width: 210px;
                                        // height: 35px;
                                        // border-radius: 3px;

                                        div.control {
                                            width: 210px;
                                            height: 35px;
                                            border: 0.25pt solid #0A2B83;
                                            border-radius: 3px;

                                            // box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.45);
                                            .valueContainer {
                                                padding: 2px 12px;
                                            }

                                            span.arrowIcon {
                                                width: 36px;
                                                // border-left: 0.25pt solid #808080;
                                                background-color: transparent;
                                            }
                                        }

                                        .options {
                                            margin-top: 5px;
                                            box-shadow: 0 0 2px 0px rgba($color: #000000, $alpha: 0.65);

                                            .option {
                                                height: 46px;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                            }

                                            .selected {
                                                background-color: #E9EDF5;
                                                color: inherit;
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }

                div.btnsArea {

                    button,
                    input[type="submit"] {
                        width: 94px;
                        height: 34px;
                        border: none;
                        outline: none;
                        font-family: 'LeferiPointWhite';
                        font-size: 14px;
                        line-height: 100%;

                        &.backBtn {
                            background-color: #0A2B83;
                            color: #FFF;
                        }
                    }
                }
            }
        }

        &.editMyInfo {
            .pageContent {
                .editMyInfoBtn {
                    border: 1pt solid #45BFCE;
                    background-color: #FFF;
                    font-size: 14px;
                    color: #45BFCE;
                    text-align: center;
                    line-height: calc(34px - 2pt);
                }

                form {
                    width: inherit;
                    height: inherit;
                    display: inherit;
                    flex-direction: inherit;
                    justify-content: inherit;
                    align-items: inherit;

                    table.myInfoTbl {
                        tbody {
                            tr {
                                td {
                                    &.infoTd {
                                        position: relative;

                                        div.isEditing {
                                            position: absolute;
                                            top: 50%;
                                            right: 22px;
                                            transform: translateY(-50%);

                                            &.invisible {
                                                display: none;
                                            }

                                            &.visible {
                                                display: block;
                                            }

                                            width: 24px;
                                            height: 24px;
                                            background-image: url(../../images/editingInputIcon.png);
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            background-size: contain;
                                        }

                                        input[type="email"],
                                        input[type="text"] {
                                            width: 100%;
                                            height: 100%;

                                            border: none;
                                            outline: none;
                                            font-family: 'LeferiPointWhite';
                                            font-size: 16px;
                                            color: #4D4D4D;
                                        }

                                        button.changePwBtn {
                                            position: absolute;
                                            top: 50%;
                                            right: 12px;
                                            transform: translateY(-50%);
                                            border: none;
                                            outline: none;
                                            width: 76px;
                                            height: 38px;
                                            background-color: #45BFCE;
                                            font-family: 'LeferiPointWhite';
                                            font-size: 16px;
                                            line-height: 100%;
                                            color: #FFF;
                                        }
                                        &.myRank {
                                            background-color: #CCC;
                                        }
                                    }
                                }

                                &.edited {
                                    td {
                                        border-left: 0.25pt solid #0A2B83;
                                        border-top: 0.25pt solid #0A2B83;
                                        border-bottom: 0.25pt solid #0A2B83;

                                        &.labelTd {}

                                        &.infoTd {
                                            border-right: 0.25pt solid #0A2B83;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .btnsArea {
                        .submitBtn {
                            background-color: #0A2B83;
                            color: #FFF;
                            margin-right: 14px;
                        }

                        .cancelBtn {
                            background-color: #E9EDF5;
                            border: 0.25pt solid #808080;
                            color: #4D4D4D;
                        }
                    }
                }
            }
        }
    }


}