.changePwModal {
    width: 523px;
    height: 382px;
    padding-top: 37px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    * {
        font-family: 'LeferiPointWhite';
    }

    .closeModal {
        position: absolute;
        top: 13px;
        right: 13px;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: #0A2B83;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 12px;
            height: 12px;
            object-fit: contain;
        }
    }

    h3.modalTitle {
        text-align: center;
        color: #0A2B83;
        font-size: 20px;
    }

    form {
        width: 314px;
        height: 256px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        div.inputArea {
            width: 100%;
            height: 195px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            p.changePwInputP {
                position: relative;

                input {
                    width: 314px;
                    height: 50px;
                    background-color: #E9EDF5;
                    outline: none;
                    border: none;
                    border-radius: 7px;
                    padding: 0 14px;
                    font-size: 16px;
                    line-height: 50px;
                    color: #4D4D4D;

                    &::placeholder {
                        color: #4D4D4D;
                    }
                }

                span.errMsg {
                    position: absolute;
                    left: 4px;
                    top: calc(100% + 4px);
                    display: none;
                    font-size: 10px;
                    color: red;

                    &.invalid {
                        color: #CCC;
                        display: inline-block;
                    }
                }

                &.error {
                    span.errMsg {
                        display: inline-block;
                    }
                }

                &.invalid {
                    span.errMsg.invalid {
                        color: red;
                    }
                }

                &.dup {
                    span.errMsg.invalid {
                        display: none;
                    }

                    span.errMsg.dup {
                        display: inline-block;
                    }
                }
            }
        }

        div.btnsArea {
            width: 172px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .formBtn {
                width: 76px;
                height: 30px;
                outline: none;
                border: none;
                border-radius: 2px;
                font-size: 14px;
                line-height: 30px;
                color: #FFF;

                &.submitBtn {
                    background-color: #0A2B83;
                }

                &.cancelBtn {
                    background-color: #B3B3B3;
                }
            }
        }
    }
}