div.notiWindow.fadeoutNoti {
    width: 272px;
    height: 71px;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-duration: 3s;
    animation-name: fadeOut;
    animation-iteration-count: 1;
    padding: 0;
    div.innerDiv {
        width: 100%;
        height: 100%;
        display: flex;
    justify-content: center;
    align-items: center;
        p {
            font-family: 'LeferiPointWhite';
            font-size: 16px;
            color: #4D4D4D;
        }
    }
}

@keyframes fadeOut {
    70% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}