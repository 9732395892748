.registerPanel {
    h1 {
        color: #0A2B83;
        font-family: 'LeferiBaseRegular';
        font-size: 48px;
        text-align: center;
        margin-bottom: 35px;
    }

    h2 {
        // width: 316px;
        color: #0A2B83;
        font-family: 'NotoSansKR';
        font-size: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 75px;

        img.logoImg {
            width: 45px;
            height: 45px;
            margin-right: 28px;
        }
    }

    form.registerForm {
        * {
            line-height: 1;
        }

        width: 485px;
        height: 460px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        div.inputsArea {
            width: 100%;
            height: 357px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            &.hasProvider {
                height: 200px;
            }

            div.emailInputArea {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                div.inputArea {
                    width: 357px;

                    span.emailDupChkPassed {
                        &.invisible {
                            display: none;
                        }

                        &.visible {
                            display: block;
                        }
                    }
                }

                button.dupChkBtn {
                    width: 112px;
                    height: 44px;
                    border-radius: 9px;
                    border: 1px solid #0A2B83;
                    outline: none;
                    background-color: #0A2B83;
                    color: #FFF;
                    font-family: 'LeferiPointWhite';
                    font-size: 16px;

                    &:hover:not(:disabled) {
                        color: #0A2B83;
                        background-color: #FFF;
                    }
                    &:disabled {
                        background-color: #7F8081;
                        border: none;
                        cursor: default;
                    }
                }
            }

            div.inputArea {
                position: relative;
                width: 100%;
                height: 44px;

                &.invisible {
                    display: none;
                }

                input {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #0A2B83;
                    border-radius: 10px;
                    outline: none;
                    color: #0A2B83;
                    padding: 0 28px;
                    font-family: 'LeferiPointWhite';
                    font-size: 16px;

                    &::placeholder {
                        color: #0A2B83;
                    }

                    &:focus {
                        box-shadow: 0px 3px 3px rgba($color: #000000, $alpha: 0.25);
                    }
                    &:disabled {
                        border: none;
                        background-color: #E5E5E5;
                        color: #323333;
                    }
                }

                span.errMsgSpan,
                span.emailDupChkPassed,
                span.pwFormatSpan {
                    position: absolute;
                    top: calc(100% + 6px);
                    left: 23px;
                    font-family: 'LeferiPointWhite';
                    font-size: 12px;

                    &.errMsgSpan {
                        color: #FF0000;
                    }

                    &.emailDupChkPassed {
                        color: #0A2B83;
                    }
                    &.pwFormatSpan {
                        color: #CCC;
                    }
                }
               

                .errMsgSpan {
                    display: none;
                }

                &.error {

                    input {
                        border: 1px solid #FF0000;
                        color: #FF0000;

                        &::placeholder {
                            color: #FF0000;
                        }
                    }

                    .errMsgSpan {
                        display: block;
                    }
                }

                &.userEmail {
                    .errMsgSpan {
                        display: none;   
                    }
                    &.error {
                        &.noValue {
                            .errMsgSpan.noValueError {
                                display: block;
                            }
                        }
                        &.invalidEmail {
                            .errMsgSpan.invalidEmailError {
                                display: block;
                            }
                        }
                        &.dupEmail {
                            .errMsgSpan.dupEmailError {
                                display: block;
                            }
                        }
                    }
                }
            }

        }

        div.btnsArea {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            input.registerBtn,
            .cancelBtn {
                width: 225px;
                height: 46px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                outline: none;
                color: #FFF;
                background-color: #0A2B83;
                font-family: 'LeferiBaseRegular';
                font-size: 16px;
                border-radius: 8px;
                border: 1px solid #0A2B83;

                &:disabled {
                    background-color: #CCC;
                    border: none;
                    cursor: default;
                }

                &:hover:not(:disabled) {
                    color: #0A2B83;
                    background-color: #FFF;
                }
            }

        }

    }

    &.socialRegister {
        h2 {
            margin-bottom: 40px;
        }
        div.providerInfoArea {
            height: 127px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 38px;
            img {
                height: 43px;
            }
            p {
                line-height: 1;
                font-family: 'LeferiPointWhite';
                font-size: 16px;
                text-align: center;
                color: #333333;
                br {
                    display: block;
                    content: '';
                    margin: 12px;
                }
            }
        }
        form.registerForm {
            height: 331px;
        }
    }
}