.cancelEditData {
    width: 358px;
    height: 184px;
    padding-top: 52px;
    padding-bottom: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .closeModal {
        position: absolute;
        top: 13px;
        right: 13px;
        width: 28px;
        height: 28px;
        border-radius: 14px;
        background-color: #0A2B83;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 10px;
            height: 10px;
            object-fit: contain;
        }
    }

    .alertMsg {
        height: 50px;
        font-family: 'LeferiPointWhite';
        font-size: 16px;
        color: #4D4D4D;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .btnsArea {
        width: 172px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            width: 76px;
            height: 30px;
            border-radius: 2px;
            border: none;
            outline: none;
            font-family: 'LeferiPointWhite';
            font-size: 14px;
            color: #FFF;
            &.exitBtn {
                background-color: #0A2B83;
            }
            &.cancelBtn {
                background-color: #B3B3B3;
            }
        }
    }
}