div.editSumDataStruct {
    .pageTitle {
        margin-bottom: 29px;
    }

    table {
        border-collapse: collapse;
        width: 1100px;
        font-family: 'LeferiPointWhite';
        font-size: 14px;

        tr {
            &:first-child {
                th {
                    border-top-left-radius: 7px;
                }

                td {
                    border-top: 0.25pt solid #808080;
                }
            }

            &:last-child {
                th {
                    border-bottom-left-radius: 7px;
                    border-bottom: none;
                }

                td {
                    border-bottom: 0.25pt solid #808080;
                }
            }

            th,
            td {
                border-right: 0.25pt solid #808080;
                border-bottom: 0.25pt solid #808080;
                height: 30px;
            }

            th {
                width: 120px;
                background-image: linear-gradient(60deg, #45BFCE 0%, #0A2B83 100%);
                border-bottom: 0.25pt solid #FFF;
                color: #fff;
            }

            td {
                &.indexTd {
                    width: 50px;
                }

                input {
                    border: none;
                    // outline: none;
                    width: 100%;
                    line-height: 28px;
                    padding: 0 20px;
                    text-align: center;
                    font-family: 'LeferiPointWhite';
                    font-size: 14px;
                }
            }
        }

    }

    .btnsArea {
        display: flex;
        width: 1100px;
        margin-top: 20px;
        justify-content: flex-end;
        align-items: center;

        button,
        input[type="submit"] {
            width: 94px;
            height: 34px;
            font-family: 'LeferiPointWhite';
            font-size: 14px;
            outline: none;
            line-height: calc(34px - 0.5pt);

            &.backBtn {
                // width: ;
                line-height: 1;
            }

            &.submitBtn {
                background-color: #0A2B83;
                color: #FFF;
                border: 0.25pt solid #0A2B83;
            }

            &.cancelBtn {
                margin-left: 14px;
                background-color: #E9EDF5;
                color: #4D4D4D;
                border: 0.25pt solid #808080;
                border-radius: 0;
            }
        }
    }
}