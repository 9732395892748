div.showModelsArea {
    h2.pageTitle {
        padding-bottom: 32px;
    }

    div.modelsTblArea {
        width: 400px;
        font-size: 16px;
        font-family: 'LeferiPointWhite';
        position: relative;

        .hintBtn {
            right: 0;
            bottom: calc(100% + 14px);
        }

        ul.modelsTbl li.row {
            ul {
                display: flex;

                li.col {
                    display: flex;
                    justify-content: center;
                    align-items: center;


                    &:nth-child(1) {
                        width: 98px;
                    }

                    &:nth-child(2) {
                        width: 148px;
                    }

                    &:nth-child(3) {
                        width: 154px;
                    }
                }
            }

            &.th {
                background-image: linear-gradient(120deg, #45BFCE 0%, #0A2B83 100%);
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;

                li.col {
                    height: 40px;
                    color: #FFF;
                    border: none;

                    &:not(:last-child) {
                        border-right: 0.25px solid #FFF;
                    }
                }
            }

            &:not(.th) {
                ul {
                    display: flex;

                    li.col {
                        height: 27px;
                        border-bottom: 0.25px solid #808080;
                        border-left: 0.25px solid #808080;
                        color: #4D4D4D;

                        div.inputArea {
                            width: 100%;
                            height: 100%;

                            input {
                                width: 100%;
                                height: 100%;
                                border: none;
                                outline: none;
                                text-align: center;
                                font-size: 12px;
                                font-family: 'LeferiPointWhite';
                                color: #4D4D4D;
                            }
                        }

                        &:nth-child(3) {
                            border-right: 0.25px solid #808080;
                        }
                    }
                }
            }

            &.newRow {
                .selectBox {
                    height: 100%;
                    border: none;
                    outline: none;
                    .control {
                        min-height: auto;
                        height: 100%;
                        border: none;
                        outline: none;
                        border-radius: 0;
                        font-size: 13px;
                        text-align: center;
                        .css-1dimb5e-singleValue {
                            color: #0A2B83;
                        }
                        .arrowIcon {
                            background-color: transparent;
                            // height: 20px;
                            padding-right: 7px;
                            img {
                                width: 11px;
                                height: 6px;
                            }
                        }
                    }
                    .options {
                        margin-top: 10px;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    div.btnsArea {
        width: 400px;
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
    }

    // div.editModelsArea {
    //     div.inputArea {
    //         input {
    //             width: 100%;
    //         }
    //     }
    // }
}