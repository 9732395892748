.cancelEditData {
    width: 503px;
    height: 260px;
    border-radius: 14px;
    padding-top: 70px;
    padding-bottom: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .closeModal {
        position: absolute;
        top: 18px;
        right: 19px;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        background-color: #0A2B83;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 18px;
            height: 18px;
            object-fit: contain;
        }
    }

    .alertMsg {
        height: 55px;
        font-family: 'LeferiPointWhite';
        font-size: 20px;
        color: #4D4D4D;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .btnsArea {
        width: 268px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            width: 127px;
            height: 50px;
            border-radius: 4px;
            border: none;
            outline: none;
            font-family: 'LeferiPointWhite';
            font-size: 20px;
            color: #FFF;
            &.exitBtn {
                background-color: #0A2B83;
            }
            &.cancelBtn {
                background-color: #B3B3B3;
            }
        }
    }
}