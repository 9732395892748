div.selectBox {

    .css-1f43avz-a11yText-A11yText {
        display: none;
    }

    .control {

        // .css-qbdosj-Input {
        //     display: none;
        // }
        .css-mohuvp-dummyInput-DummyInput {
            width: 0;
            height: 0;
        }

        span.arrowIcon {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #E9EDF5;

            img {
                object-fit: contain;
            }

            &.focused img {

                transform: rotate(180deg);
            }
        }
    }

    .options {
        background-color: #FFF;

        .option {
            color: #0A2B83 !important;
            &.selected {
                background-color: #CAD6EA;
            }
            &.focused {
                background-color: #E9EDF5;
            }
        }


        // overflow-y: scroll;

        // /* width */
        // &::-webkit-scrollbar {
        //     width: 5px;
        // }

        /* Handle */
        // &::-webkit-scrollbar-thumb {
        //     background: #0A2B83;
        //     border-radius: 3px;
        //     border: 10px solid transparent;
        // }

        // /* Track */
        // &::-webkit-scrollbar-track {
        //     background: #f1f1f1;
        //     margin-top: 14px;
        //     margin-bottom: 14px;
        // }


    }

}