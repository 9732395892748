div.modalContentArea.confirmModal {
    padding-top: 42px;
    padding-bottom: 32px;
    padding-left: 50px;
    padding-right: 50px;
    min-width: 308px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    p.msg {
        font-family: 'LeferiPointWhite';
        font-size: 16px;
        color: #4D4D4D;
        line-height: 1;
    }

    div.btnsArea {
        width: 172px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            width: 76px;
            height: 30px;
            border: none;
            outline: none;
            border-radius: 2px;
            font-family: LeferiPointWhite;
            font-size: 14px;
            color: #FFF;
            line-height: 30px;

            &:nth-child(1) {
                background-color: #0A2B83;
            }

            &:nth-child(2) {
                background-color: #B3B3B3;
            }
        }
    }
}