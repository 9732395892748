.checkDelete {
    width: 340px;
    height: 176px;
    padding-top: 48px;
    padding-bottom: 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .closeModal {
        position: absolute;
        top: 10px;
        right: 11px;
        width: 36px;
        height: 36px;
        border-radius: 18px;
        background-color: #0A2B83;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 13px;
            height: 13px;
            object-fit: contain;
        }
    }

    .alertMsg {
        height: 55px;
        font-family: 'LeferiPointWhite';
        font-size: 16px;
        color: #4D4D4D;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .btnsArea {
        width: 172px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            width: 76px;
            height: 30px;
            border-radius: 2px;
            border: none;
            outline: none;
            font-family: 'LeferiPointWhite';
            font-size: 14px;
            line-height: 30px;
            color: #FFF;
            &.deleteBtn {
                background-color: #0A2B83;
            }
            &.cancelBtn {
                background-color: #B3B3B3;
            }
        }
    }
}