.dsPage {
    padding: 0 !important;
    div.dsMenuArea {
        width: 150px;
        position: fixed;
        height: calc(100vh - 75px);
        background-color: #F2F2F2;
        padding-top: 310px;
        .dsMenuLi {
            margin-bottom: 16px;

            .dsMenuBtn {
                background-color: transparent;
                text-align: start;
                font-size: 15px;
                height: 25px;
                font-family: 'NanumSquare';
                color: #4D4D4D;
                padding: 0px;
                display: flex;
                // justify-content: ;
                align-items: center;
                position: relative;

                .dsMenuIcon {
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    transform: translateY(-50%);
                }

                span {
                    margin-left: 36px;
                }

                &.on {
                    color: #0A2B83;

                    span {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    div.dsContentArea {
        padding: 60px 110px 76px 200px;
        width: 100%;
        // height: 100%;
        height: var(--content-min-height);

        // position: relative;
        >div {
            position: relative;

            .path {
                top: -45px !important;
                left: 0px !important;
            }
        }
    }
}