.monitoringPage {
    width: 100%;
    height: 100%;

    h2.pageTitle {
        margin-bottom: 32px;
    }

    div.btnsArea {
        width: 100%;
        height: 123px;
        background-color: #EFF4F9;
        border-radius: 19px;
        padding: 11px 135px;
        margin-bottom: 32px;
        margin-right: 10px;

        div.scrollArea {
            height: 100%;
            // overflow-x: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div.sensorOnOffArea {
                width: 200px;
                height: 100%;
                padding: 11px 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: 'NanumSquare';

                .sensorTypeRadio {
                    color: #0A2B83;
                    display: none;
                }

                .sensorTitle {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    color: #000000;
                    cursor: pointer;

                    .radioVisual {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 24px;
                        height: 24px;
                        border: 1pt solid #0A2B83;
                        border-radius: 50%;
                        background-color: #FFF;
                        margin-right: 10px;

                        &.on::after {
                            content: '';
                            width: 14px;
                            height: 14px;
                            border-radius: 50%;
                            background-color: #0A2B83;
                            // width: ;
                        }
                    }

                }

                .setName {
                    font-size: 14px;
                    color: #000000;
                    margin-right: 10px;
                }

                .toggleBtn {
                    width: 59px;
                    height: 26px;
                    border-radius: 13px;
                    transition: 0.4s;
                    overflow: hidden;
                    font-family: 'NanumSquare';
                    font-size: 14px;

                    >div {
                        width: 92px;
                        height: 100%;
                        padding-left: 7px;
                        padding-right: 10px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        transition: 0.4s;

                        position: relative;
                        top: 0;

                        .toggleIcon {
                            width: 21px;
                            height: 21px;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: 0.4s;
                        }
                    }

                    &.on {
                        background-color: #FFF;
                        border: 1pt solid #45BFCE;

                        >div {
                            color: #45BFCE;
                            transform: translateX(-33px);

                            .toggleIcon {
                                background-color: #45BFCE;
                            }
                        }
                    }

                    &.off {
                        background-color: #CCCCCC;
                        border: 1pt solid #4D4D4D;

                        >div {
                            color: #4D4D4D;
                            transform: translateX(0);

                            .toggleIcon {
                                background-color: #4D4D4D;
                            }
                        }
                    }
                }
            }
        }
    }

    div.monitArea {
        width: 100%;
        // height: calc(100vh - 428px);
        height: 568px;
        display: flex;
        justify-content: space-between;

        div.dataTitleListArea {
            width: 239px;
            // height: calc(100vh - 350px - 38px);
            height: 100%;

            div.selectDataTitle {
                width: 100%;
                height: 38px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-family: 'LeferiPointWhite';
                font-size: 16px;
                background-image: linear-gradient(120deg, #45BFCE 0%, #0A2B83 100%);
                border-top-left-radius: 13px;
                border-top-right-radius: 13px;
            }

            button.getPrevDataBtn,
            button.getNextDataBtn {
                width: 100%;
                height: 31px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EFF4F9;
                border: 0.25px solid #808080;
                outline: none;

                &:disabled {
                    cursor: default;
                    background-color: #CCC;
                }

                img {
                    width: 11px;
                    height: 8px;
                }
            }

            .dataTitleContainer {

                // height: calc(100% - 38px);
                // overflow-y: scroll;
                ul.dataTitleUl {
                    // width: 100%;
                    width: 239px;

                    li.dataTitleLi {
                        width: 100%;
                        border-left: 1px solid #333;
                        border-top: 1px solid #333;
                        border-right: 1px solid #333;

                        &:first-child {
                            border-top: none;
                        }

                        &:last-child {
                            // border-bottom: 1px solid #333;
                        }

                        button.dataTitleBtn {
                            width: 100%;
                            height: 30px;
                            border: none;
                            outline: none;
                            background-color: #FFF;
                            font-family: 'LeferiPointWhite';
                            font-size: 12px;
                            color: #4D4D4D;

                            &.selected {
                                background-color: #45BFCE;
                                color: #FFF;
                            }
                        }

                        &.moreData {
                            button {
                                font-size: 24px;
                                line-height: 1;
                                background: linear-gradient(120deg, #45BFCE 0%, #0A2B83 100%);
                                color: #FFF;

                                &:disabled {
                                    background: #EEE;
                                    color: #CCC;
                                }
                            }
                        }

                    }
                }
            }
        }

        div.monitPrintArea {
            display: flex;
            flex-direction: column;
            width: calc(100% - 294px);
            // padding-right: 40px;
            // height: calc(100vh - 298px);
            height: 100%;
            // overflow-y: auto;
            position: relative;
            // &::-webkit-scrollbar {
            //     display: none;
            // }

            div.monitContainer {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;

                div.monitTblDiv {
                    height: 160px;
                    width: 100%;
                    // overflow-y: scroll;
                    // background-color: #EFF4F9;
                    // border-radius: 10px;
                    // padding: 23px 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    div.monitTblTop {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        // padding-right: 40px;
                        position: relative;

                        &::after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 40px;
                            height: 100%;
                            z-index: 1;
                            background-image: linear-gradient(to left, #fff 0%, #fff 50%, transparent 100%);
                        }

                        div.sumDataInfoDiv {
                            width: 729px;
                            height: 43px;
                            border-radius: 5px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            background-color: #EEF2F7;
                            padding-left: 26px;
                            padding-right: 22px;

                            div.sumDataInfoArea {
                                display: flex;
                                align-items: center;

                                p {
                                    font-family: 'LeferiPointWhite';
                                    font-size: 12px;
                                    line-height: 1;
                                    color: #4A4A4A;

                                    &:nth-child(1) {
                                        margin-right: 48px;
                                        min-width: 72px;
                                    }

                                    &:nth-child(2) {
                                        margin-right: 44px;
                                        min-width: 48px;
                                    }

                                    &:nth-child(3) {
                                        margin-right: 60px;
                                        min-width: 115px;
                                    }

                                }
                            }

                            .toSumData {
                                font-family: 'LeferiPointWhite';
                                font-size: 14px;
                                color: #FFF;
                                width: 115px;
                                height: 26px;
                                background-color: #45BFCE;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 13px;
                                // margin-right: 38px;
                                border: none;
                                outline: none;
                                line-height: 1;

                                span.icon {
                                    display: block;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 9px;
                                    background-color: #FFF;
                                    color: #45BFCE;
                                    font-weight: 900;
                                    line-height: 18px;
                                    margin-left: 8px;
                                }

                                &:disabled {
                                    background: #EEE;
                                    color: #CCC;
                                    cursor: default;

                                    * {
                                        background: #EEE !important;
                                        color: #CCC !important;
                                    }
                                }
                            }
                        }

                        div.btnsArea {
                            width: 400px;
                            margin: 0;
                            padding: 0;
                            height: 100%;
                            background-color: transparent;
                            position: relative;
                            border-radius: 0;
                            overflow: hidden;

                            div.viewModeBtnsArea {
                                margin-right: 40px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                display: flex;
                                height: 26px;
                                overflow: hidden;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    // right: 0;
                                    display: block;
                                    width: 720px;
                                    height: 100%;
                                    // background-color: #000000;
                                    background-image: linear-gradient(to left, #FFF 0%, #FFF 50%, transparent 100%);

                                }

                                &.invisible {
                                    // 사라질 때
                                    right: -360px;
                                    transition: right 0s;
                                    transition-delay: 1s;
                                    z-index: 0;

                                    &::after {
                                        // width: 447px;
                                        // transition: width 1s ease-out;
                                        right: 0;
                                        transition: right 1s ease-out;
                                    }
                                }

                                &.visible {
                                    // 등장할 때
                                    right: 0;
                                    transition: right 1.4s ease 500ms;
                                    z-index: 1;

                                    &::after {
                                        // width: 0;
                                        // transition: width 0;
                                        right: -720px;
                                        transition: right 0;
                                    }
                                }



                                .saveCSVBtn {
                                    font-family: 'LeferiPointWhite';
                                    font-size: 14px;
                                    color: #FFF;
                                    width: 99px;
                                    height: 26px;
                                    background-color: #1254B5;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 13px;
                                    margin-right: 22px;
                                    line-height: 1;

                                    img {
                                        width: 15px;
                                        height: 13px;
                                        margin-right: 6px;
                                    }
                                }

                                .deleteDataBtn {
                                    width: 93px;
                                    height: 26px;
                                    border-radius: 13px;
                                    font-family: 'LeferiPointWhite';
                                    font-size: 14px;
                                    color: #FFF;
                                    background-color: #45BFCE;
                                    border: none;
                                    outline: none;
                                }

                                .editModeBtn {
                                    width: 26px;
                                    height: 26px;
                                    margin-left: 24px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-right: 0;

                                    img {
                                        width: 13px;
                                        height: 13px;
                                    }

                                    // background-color: #0A2B83;
                                    &.activated {
                                        box-shadow: inset 3px 3px 5px 0 rgba(0, 0, 0, 0.2),
                                            inset -3px -3px 5px 0 rgba(255, 255, 255, 0.5);
                                        // background-color: #45a6b3;
                                        color: #FFF;
                                    }
                                }
                            }


                            div.editModeBtnsArea {
                                margin-right: 40px;
                                display: flex;
                                justify-content: flex-end;
                                height: 26px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                overflow: hidden;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    // right: 0;
                                    display: block;
                                    width: 720px;
                                    height: 100%;
                                    // background-color: #000000;
                                    background-image: linear-gradient(to left, #FFF 0%, #FFF 50%, transparent 100%);

                                }

                                &.invisible {
                                    // 사라질 때
                                    right: -360px;
                                    transition: right 0s 1s;
                                    z-index: 0;

                                    &::after {
                                        // width: 447px;
                                        // transition: width 1s ease-out;
                                        right: 0;
                                        transition: right 1s ease-out;
                                    }
                                }

                                &.visible {
                                    // 등장할 때
                                    right: 0;
                                    z-index: 1;
                                    // transition: right 400ms ease-out 300ms;
                                    transition: right 1s ease-out 200ms;

                                    &::after {
                                        // width: 0;
                                        transition: right 0s;
                                        right: -720px;
                                    }
                                }

                            }
                        }
                    }

                    div.monitTblArea {
                        margin-top: 13px;
                        width: 100%;
                        // height: 163px;
                        height: 100%;
                        // height: 170px;
                        // height: fit-content;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-right: 40px;
                        // overflow-y: scroll;

                        div.monitTbl {
                            width: 100%;
                            // height: 100%;
                            height: fit-content;
                            display: flex;
                            // display: grid;
                            // grid-template-columns: 97px 1fr;
                            font-family: 'LeferiPointWhite';
                            font-size: 12px;

                            >div {
                                height: fit-content;

                                // height: 100%;
                                // overflow-y: overlay;
                                &.thead {
                                    background-image: linear-gradient(120deg, #45BFCE 0%, #0A2B83 100%);
                                    border-top-left-radius: 11px;
                                    border-bottom-left-radius: 11px;
                                    color: #FFF;

                                    li.th {
                                        font-size: 16px;

                                        >div {
                                            width: 121px;
                                        }

                                        &:not(:last-child) {
                                            border-bottom: 0.25px solid #FFF;
                                        }
                                    }
                                }

                                &.tbody {

                                    // width: calc(1151px - 97px);
                                    width: calc(100% - 97px);
                                    height: fit-content;


                                    ul.tbodyUl {
                                        display: flex;
                                        height: 100%;

                                        li.col {
                                            >div {
                                                width: 101px;
                                                background-color: #FFF;

                                                ul li.row {
                                                    border-top: 0.25px solid #808080;
                                                    border-right: 0.25px solid #808080;

                                                    &:last-child {
                                                        border-bottom: 0.25px solid #808080;
                                                    }

                                                    input {
                                                        width: 100%;
                                                        height: 100%;
                                                        font-family: 'LeferiPointWhite';
                                                        font-size: 12px;
                                                        text-align: center;
                                                        border: none;
                                                        outline: none;

                                                        &::-webkit-inner-spin-button {
                                                            display: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                ul {
                                    li.row {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        text-align: center;
                                        height: 28px;
                                    }
                                }
                            }

                        }
                    }
                }

                div.chartArea {
                    width: 100%;
                    padding-right: 40px;

                    div.lineChartArea {
                        margin-top: 15px;
                        width: 100%;
                        // height: calc(100% - 236px);
                        height: 393px;
                        // height: 456px;
                        background-color: #EFF4F9;
                        // padding-top: 20px;
                        // padding-bottom: 16px;
                        border-radius: 13px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        padding: 36px 62px 24px 51px;
                        position: relative;

                        .chartArea {
                            width: 100%;
                            // height: 100%;
                            height: 302px;
                            // height: 402px;
                            height: calc(100% - 36px);
                            // height: calc(100vh - 398px);
                            position: relative;
                            padding-top: 30px;
                            padding-bottom: 30px;
                            font-family: 'NanumSquare';
                            font-size: 16px;

                            span.axisTitle {
                                position: absolute;
                                color: #333333;

                                &.y {
                                    top: 0;
                                    left: 15px;
                                }

                                &.x {
                                    bottom: 0px;
                                    right: 0;
                                }
                            }
                        }

                        .chartInfoArea {
                            // margin-top: 40px;
                            width: 847px;
                            height: 33px;
                            background-color: #FFFFFF;
                            border: 1pt solid #45BFCE;
                            border-radius: 17px;
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            font-family: 'NanumSquare';
                            font-size: 16px;
                            color: #333;
                            line-height: 33px;
                        }

                        .legendDiv {
                            position: absolute;
                            left: 15px;
                            bottom: 10px;
                            width: 160px;
                            height: 70px;
                            border-radius: 8px;
                            background-color: #FFF;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            .legend {
                                width: 120px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                &.invisible {
                                    display: none;
                                }

                                &:not(:first-child) {
                                    margin-top: 8px;
                                }

                                .stroke {
                                    width: 30px;
                                    height: 1px;
                                }

                                p {
                                    font-family: 'LeferiPointWhite';
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}