.startDateArea,
.endDateArea,
.customDatePickerArea {
    width: 233px;

    input[type='text'] {
        text-align: center;
        width: 233px;
        height: 33px;
        font-family: 'LeferiPointWhite';
        font-size: 18px;
        color: #0A2B83;
        line-height: 33px;
        outline: none;
        border: none;
        border-bottom: 1px solid #0A2B83;
        cursor: pointer;
    }

    &.customDatePickerArea {
        input[type='text'] {
            text-align: start;
        }
    }

    .react-datepicker__tab-loop {

        // width: 233px;
        .react-datepicker__tab-loop__start {
            // display: none;
        }

        .react-datepicker-popper {
            padding: 0;
            z-index: 90;

            * {
                font-family: 'LeferiPointWhite';
            }

            .react-datepicker {
                width: 233px;
                // height: 288px;
                border: 0.5pt solid #0A2B83;
                border-radius: 8px;
                display: flex;
                flex-direction: column;

                .react-datepicker__triangle {
                    display: none;
                }

                .react-datepicker__header {
                    background-color: transparent;
                    border: none;

                    .customHeader {
                        height: 28px;
                        display: flex;
                        padding: 0 9px;
                        justify-content: space-between;
                        align-items: center;

                        button.navigation {
                            // position: absolute;
                            width: 28px;
                            height: 28px;
                            background-color: #E9EDF5;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            outline: none;
                            border: none;
                            border-radius: 4px;

                            // &.prevBtn {
                            //     top: 11px;
                            //     left: 9px;
                            // }

                            // &.nextBtn {
                            //     top: 11px;
                            //     right: 9px;
                            // }
                        }

                        .selectBox {
                            position: relative;
                            border: none;
                            border-radius: 0;
                            box-shadow: none;
                            width: 137px;
                            height: 28px;

                            select {
                                position: relative;
                                z-index: 2;
                                background-color: transparent;
                                width: 100%;
                                // height: inherit;
                                height: 100%;
                                border: none;
                                outline: none;                                color: #4D4D4D;
                                font-size: 18px;
                                padding-left: 9px;
                                padding-right: 24px;
                            }
                            span.arrowIcon {
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 24px;
                                height: 100%;
                                z-index: inherit;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: transparent;
                
                                img {
                                    object-fit: contain;
                                }
                            }
                
                            select:focus+.arrowIcon img {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .react-datepicker__day-names {
                        padding: 0 12px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .react-datepicker__day-name {
                            font-family: 'NotoSansKR';
                            font-weight: 300;
                            color: #808080;
                            font-size: 14px;
                            width: 28px;
                            height: 28px;
                            margin: 0;
                        }
                    }
                }

                .react-datepicker__month {
                    margin: 0;
                    padding: 12px;

                    * {
                        font-size: 14px;
                    }

                    .react-datepicker__week {
                        display: flex;
                        justify-content: space-between;
                        margin: 1.2px 0;

                        .react-datepicker__day {
                            width: 28px;
                            height: 28px;
                            margin: 0;
                            line-height: 28px;
                            position: relative;
                            color: #333333;

                            &:hover {
                                border-radius: 14px !important;
                                background-color: #E9EDF5 !important;
                            }

                            &.react-datepicker__day--selecting-range-start,
                            &.react-datepicker__day--selecting-range-end {
                                border-radius: 14px !important;
                                background-color: #E9EDF5 !important;
                            }

                            &.react-datepicker__day--today {
                                font-weight: inherit;

                                &::after {
                                    content: '';
                                    width: 100%;
                                    height: 100%;
                                    border: 1pt solid #0A2B83;
                                    border-radius: 14px;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }

                            &.react-datepicker__day--selected {
                                border-radius: 14px;
                                background-color: #E9EDF5 !important;
                            }

                            &.react-datepicker__day--in-selecting-range,
                            &.react-datepicker__day--keyboard-selected,
                            &.react-datepicker__day--in-range {
                                color: #333333;

                                background-color: transparent;
                            }

                            &.react-datepicker__day--outside-month {
                                color: #B3B3B3;
                            }

                            &.react-datepicker__day--disabled:hover {
                                background-color: transparent !important;
                            }
                        }
                    }
                }

                .react-datepicker__input-time-container {
                    margin: 0 0 15px 0;
                    padding: 3px 24px;
                    width: 100%;

                    * {
                        margin: 0;
                    }

                    .react-datepicker-time__caption {
                        display: none;
                    }

                    .react-datepicker-time__input-container {
                        // padding: ;
                        width: inherit;

                        .react-datepicker-time__input {
                            width: inherit;

                            .timeNSubmit {
                                display: flex;
                                width: 100%;
                                // height: 74px;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;

                                .timeInputArea {
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    >span {
                                        font-size: 13px;
                                    }

                                    .inputArea {
                                        position: relative;

                                        .timeInput {
                                            width: 72px;
                                            height: 26px;
                                            outline: none;
                                            border: 0.25pt solid #0A2B83;
                                            border-radius: 5px;
                                            text-align: center;

                                            &::-webkit-inner-spin-button,
                                            &::-webkit-outer-spin-button {
                                                appearance: none;
                                                -webkit-appearance: none;
                                                -moz-appearance: none;
                                            }
                                        }

                                        &:hover {
                                            div.btnsArea {
                                                display: flex;
                                            }
                                        }

                                        div.btnsArea {
                                            position: absolute;
                                            top: 50%;
                                            right: 3px;
                                            transform: translateY(-50%);
                                            width: 12px;
                                            height: 20px;
                                            // display: flex;
                                            display: none;
                                            flex-direction: column;

                                            button {
                                                width: 12px;
                                                height: 9px;
                                                border: none;
                                                outline: none;
                                                background-color: #0A2B83;
                                                border-radius: 2px;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;

                                                img {
                                                    width: 6px;
                                                    height: 5px;
                                                    object-fit: contain;
                                                }
                                            }
                                        }

                                    }
                                }

                                button.submitBtn {
                                    width: 100%;
                                    height: 28px;
                                    border: none;
                                    border-radius: 4px;
                                    background-color: #0A2B83;
                                    font-family: 'LeferiPointWhite';
                                    font-size: 18px;
                                    color: #FFF;
                                    line-height: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}