.fullBg {

    width: '100vw';
    height: '100vh';
    background-color: '#FFF';
    position: relative;

    .verifyEmailPopup {
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
        width: 338px;
        height: 201px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 34px;
        padding-bottom: 31px;
        box-shadow: 0px 0px 15px rgba($color: #000000, $alpha: 0.5);
        border-radius: 15px;

        .msgArea {
            height: 84px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                font-family: 'LeferiPointWhite';
                font-size: 16px;
                text-align: center;

                br {
                    content: "";
                    display: block;
                    margin: 15px;
                }
            }
        }

        .okBtn {
            font-family: 'LeferiPointWhite';
            font-size: 14px;
            color: #FFF;
            background-color: #0A2B83;
            width: 181px;
            height: 27px;
            border: none;
            outline: none;
            border-radius: 5px;
            line-height: 1;
        }
    }
}