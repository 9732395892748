.checkboxArea {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .customCheckbox {
        display: none;
    }
    .customCheckboxLabel {
        div.labelDiv {
            width: 16px;
            height: 16px;
            border-radius: 3px;
            border: 1px solid #0A2B83;
            background-color: #FFF;
            
            &.checked {
                background-position: center;
                background-repeat: no-repeat;
                background-size: 8px 7px;
                background-image: url('../../images/checkboxIcon.png');
            }
        }
    }
}