.background {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loginPanel {
        margin-top: 100px;
        margin-bottom: 100px;
        height: 762px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #0A2B83;

        div.loginTitleArea {
            height: 190px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            h2 {
                text-align: center;
                font-family: 'LeferiBaseRegular';
                font-size: 83px;
                font-weight: normal;
            }

            div.loginTitleKrArea {
                width: 451px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 45px;
                    height: 45px;
                    object-fit: contain;
                    margin-right: 28px;
                }

                p {
                    font-family: 'NotoSansKr';
                    font-weight: normal;
                    font-size: 28px;
                }
            }
        }

        div.formNBtnsArea {
            height: 494px;

            form {
                width: 505px;
                height: 378px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                div.inputArea {
                    position: relative;
                    height: 138px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    div.inputBg {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 505px;
                        height: 60px;
                        border: 0.5pt solid #0A2B83;
                        border-radius: 12px;
                        padding: 0 20px;

                        .iconNLabel {
                            width: 128px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            label {
                                width: 78px;
                                font-family: 'LeferiPointWhite';
                                font-weight: normal;
                                font-size: 16px;
                            }
                        }

                        input:not([type='submit']) {
                            width: 400px;
                            height: 40px;
                            padding: 0 30px;
                            font-family: 'LeferiPointWhite';
                            font-weight: normal;
                            font-size: 16px;
                            background-color: transparent;
                            border: none;
                            outline: none;
                            border-radius: 0px;
                        }

                    }

                    .loginFailed {

                        display: none;
                        position: absolute;
                        top: calc(100% + 27px);
                        left: 0px;

                        font-family: 'LeferiPointWhite';
                        font-weight: normal;
                        font-size: 18px;
                        color: #FF0000;

                        &.fail {
                            display: block;
                        }
                    }
                }

                div.btnsArea {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    height: 150px;
                    padding-bottom: 42px;
                    border-bottom: 1px solid #CCCCCC;

                    .loginBtn {
                        border: none;
                        outline: none;
                        width: 505px;
                        height: 60px;
                        line-height: 60px;
                        border-radius: 12px;
                        font-family: 'LeferiBaseRegular';
                        font-weight: normal;
                        font-size: 18px;
                        color: white;
                        background-color: #0A2B83;
                    }

                    .signupBtn {
                        display: block;
                        width: fit-content;
                        // position: absolute;
                        // top: calc(100% + 32px);
                        // left: 50%;
                        // transform: translateX(-50%);
                        font-family: 'LeferiBaseRegular';
                        font-size: 18px;
                    }
                }

            }
            div.socialLoginBtnsArea {
                width: 100%;
                height: 100px;
                background-color: transparent;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 25px;
                margin-top: 16px;

                >p {
                    color: #808080;
                    font-size: 18px;
                    font-family: 'LeferiBaseRegular';
                }

                ul.socialLoginBtnsUl {
                    display: flex;
                    align-items: center;
                    width: 243px;

                    li {
                        height: 43px;
                        .naverLoginBtn {
                            border: none;
                            outline: none;
                            height: 43px;
                            background-color: transparent;

                            img {
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}