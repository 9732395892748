@font-face {
    font-family: 'LeferiBaseRegular';
    font-weight: 400;
    font-style: normal;
    src: url('./Leferi/LeferiBaseRegular.otf') format('opentype');
}
@font-face {
    font-family: 'LeferiPointSpecial';
    font-weight: 400;
    font-style: normal;
    src: url('./Leferi/LeferiPointSpecial.otf') format('opentype');
}
@font-face {
    font-family: 'LeferiPointWhite';
    font-weight: 400;
    font-style: normal;
    src: url('./Leferi/LeferiPointWhite.otf') format('opentype');
}
@font-face {
    font-family: 'NotoSansKR';
    font-weight: 400;
    font-style: normal;
    src: url('./Noto_Sans_KR/NotoSansKR-VariableFont_wght.ttf') format('truetype');
}