div.showUserRankArea {
    h2.pageTitle {
        padding-bottom: 47px;
    }

    .btnsArea {
        width: 297px;
        margin-top: 22px;
        display: flex;
        justify-content: flex-end;
    }

}

div.userRankTblArea {
    width: 297px;
    font-size: 16px;
    font-family: 'LeferiPointWhite';
    position: relative;

    .hintBtn {
        right: 0;
        bottom: calc(100% + 14px);
    }

    ul.userRankTbl li.row {
        ul {
            display: flex;

            li.col {
                display: flex;
                justify-content: center;
                align-items: center;

                &:nth-child(1) {
                    width: 81px;
                }

                &:nth-child(2) {
                    width: 216px;
                }
            }
        }

        &.th {
            background-image: linear-gradient(120deg, #45BFCE 0%, #0A2B83 100%);
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            li.col {
                height: 40px;
                color: #fff;
                border: none;

                &:not(:last-child) {
                    border-right: 0.25px solid #FFF;
                }
            }
        }

        &:not(.th) {
            font-size: 10pt;

            ul {
                display: flex;

                li.col {
                    height: 27px;
                    border-left: 0.25px solid #808080;
                    border-bottom: 0.25px solid #808080;

                    div.inputArea {
                        width: 100%;
                        height: 100%;

                        input {
                            width: 100%;
                            height: 100%;
                            border: none;
                            outline: none;
                            text-align: center;
                            font-size: 12px;
                            font-family: 'LeferiPointWhite';
                            color: #4D4D4D;
                            &::-webkit-inner-spin-button {
                                display: none;
                            }
                        }
                    }

                    &:last-child {
                        border-right: 0.25px solid #808080;
                    }
                }
            }
        }

        &.newRow {
            .selectBox {
                height: 100%;
                border: none;
                outline: none;

                .control {
                    min-height: auto;
                    height: 100%;
                    border: none;
                    outline: none;
                    border-radius: 0;
                    font-size: 10pt;
                    text-align: center;

                    .css-1dimb5e-singleValue {
                        color: #0A2B83;
                    }

                    .arrowIcon {
                        background-color: transparent;
                        // height: 20px;
                        padding-right: 7px;

                        img {
                            width: 11px;
                            height: 6px;
                        }
                    }
                }

                .options {
                    margin-top: 10px;
                }
            }
        }
    }
}